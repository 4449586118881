<template>
    <div v-if="modal" class="modal employer-auth-flow-modal" :class="{ active: modal }">
        <div id="employer-auth-flow-modal" class="modal-card">
            <div class="text-with-icon">
                <font-awesome-icon class="warning" :icon="['fa', 'triangle-exclamation']" />
                <p class="title" v-html="$t(`modal.employer-auth-flow.title`)"></p>
            </div>

            <div class="modal-body">
                <p v-html="$t(`modal.employer-auth-flow.body`)"></p>
            </div>

            <div class="cta-container">
                <button id="close" class="cta cta-outline dark cta-slim" @click="close()">{{$t('modal.general.ctas.ok')}}</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EmployerAuthFlowModal',

    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        show: function(val) {
            const self = this;
            self.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        }
    },

    data() {
        return {
            modal: false,
        }
    },
    
    methods: {
        close: function() {
            const self = this;

            self.modal = false;
            self.$emit('close');
        },
    }
}
</script>

