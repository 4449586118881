<template>
    <layout :classes="classes" :routeName="routeName">
        <div class="row">
            <div class="content">
                <div id="form-container" v-if="$screen.width > 768 || state === 'login'" class="form-container">
                    <div class="title-container">
                        <div v-if="$screen.width <= 768" class="cta cta-slim cta-outline light arrow" @click="state = 'choose'"><font-awesome-icon :icon="['fa', 'arrow-left']" /></div>
                        <h1 class="heading">{{$t('login.title')}}</h1>
                    </div>
                    <div id="form" class="form login">
                        <div class="form-content">
                            <div class="input-container">
                                <label id="email-label" :class="error('email')">{{$t('login.form.email.label')}}</label>
                                <input id="email-input" :class="error('email')" v-model="data.email" type="text" :placeholder="$t('login.form.email.placeholder')" @keyup.enter="logIn()">
                                
                                <FormError :data="{...validation.email, name: 'email'}"/>
                            </div> 
                            <div class="input-container">
                                <label id="password-label" :class="error('password')">{{$t('login.form.password.label')}}</label>

                                <div class="password-container">
                                    <input id="password-input" :class="error('password')" v-model="data.password" :type="pwd_field_type" :placeholder="$t('login.form.password.placeholder')" @keyup.enter="logIn()">
                                    <span class="show-password cta cta-form dark" @click="change_password_field_type()"><font-awesome-icon :icon="['fa', pwd_field_type === 'password' ? 'eye' : 'eye-slash']" /></span>
                                </div>
                                <FormError :data="{...validation.password, name: 'password'}"/>                                
                            </div>

                            <button id="submit-button" class="" :class="['cta cta-primary submit', { loading: loading }]" @click="logIn()">{{$t('login.form.submit.label')}}</button>
                            
                            <div class="fp-container">
                                <router-link class="forgot-password" to="/password/forgot">{{$t('login.form.forgotPassword.label')}}</router-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="$screen.width > 768" class="create-account-container">
                        <div class="divider">
                            <p class="text">{{$t('login.or')}}</p>
                        </div>
                        
                        <button id="create-account" class="" :class="['cta cta-outline light']" @click="$utils._navigate_to_name('signup')">{{$t('login.signup.large')}}</button>
                    </div>

                    <div v-else class="create-account-container">
                        <button id="create-account" class="" :class="['cta cta-link light']" @click="$utils._navigate_to_name('signup')">{{$t('login.signup.small')}}</button>
                    </div>
                </div>
                
                <div id="dispatch-container" v-if="$screen.width <= 768 && state === 'choose'" class="dispatch-container">
                    <div class="heading-image-container">
                        <h1 class="heading" v-html="$t('login.dispatch.work.title')"></h1>
                        <img class="hands-phone" src="../../assets/images/hands-phone.svg" alt="hand phone background">
                    </div>
                    
                    <div class="cta-container">
                        <button id="create-account" class="" :class="['cta cta-primary']" @click="$utils._navigate_to_name_with_query('signup', { state: 'signup', choice: 'work' })">{{$t('login.dispatch.ctas.signup')}}</button>
                        <button id="login" class="" :class="['cta cta-outline light']" @click="state = 'login'">{{$t('login.dispatch.ctas.login')}}</button>
                        <button id="employer-modal" class="" :class="['cta cta-link light mt-12']" @click="modal.show = true">{{$t('login.dispatch.ctas.employer')}}</button>
                    </div>
                </div>
            </div>

            <div class="lb-bg"></div>

            <img v-if="$screen.width <= 768" class="bottom-bg mobile" src="../../assets/images/backgrounds/bg_auth_mobile.svg" alt="abstract background">
            <img v-else class="bottom-bg desktop" src="../../assets/images/backgrounds/bg_auth_desktop.svg" alt="abstract background">
            
            <EmployerAuthFlowModal :show="modal.show" @close="modal.show = false" />

            <!-- <p v-if="show_install_instructions" id="see_instructions" class="see-instructions" @click="set_show_install_on_mobile_modal(true)">{{$t('navigation.install.again', { device: meta.is_mobile ? $t('navigation.install.devices.mobile') : $t('navigation.install.devices.tablet') })}}</p> -->
        </div>
    </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Layout from '../../components/Layout.vue';
import FormError from '../../components/Utils/FormError.vue';
import EmployerAuthFlowModal from '../../components/Modals/EmployerAuthFlowModal.vue';

export default {
    name: "Login",

    components: {
        Layout,
        FormError,
        EmployerAuthFlowModal
    },

    data() {
        return {
            routeName: "login",
            state: "choose",

            classes: {
                login: true
            },

            data: {
                email: '',
                password: ''
            },

            validation: {
                email: {
                    error: false,
                    key: 'errors.validation.emailInvalid'
                },
                password: {
                    error: false,
                    key: 'errors.validation.passwordInvalid'
                }
            },

            formValidated: false,
            loading: false,

            pwd_field_type: 'password',

            modal: {
                show: false
            },

            //eslint-disable-next-line
            email_regex:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },

    watch: {
        'modal.show': function() {
            const self = this;
            if(self.modal.show) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        }
    },

    computed: {
        ...mapGetters([
            'reinitialize_temp_password',
            'show_install_instructions',
            'meta'
        ]),

        env: function() {
            return process.env.VUE_APP_ENV;
        }
    },

    methods: {
        ...mapActions([
            'setToken',
            'set_show_install_on_mobile_modal'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false

                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            if(!self.email_regex.test(self.data.email))
                self.validation.email.error = true;

            if(self.data.password.length < 10 )
                self.validation.password.error = true;

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        logIn: function() {
            const self = this;
            
            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                const data = {
                    email: self.data.email,
                    password: self.data.password
                };

                self.$axios
                    .post('/login', data)
                    .then(response => {
                        self.loading = false;

                        if(response.status == 200) {
                            if(response.data){
                                self.setToken(response.data);
                                
                                if(self.reinitialize_temp_password) {
                                    if (self.$route.query.redirect) {
                                        self.$utils._navigate_to_name_with_query('change-temporary-password', self.$route.query)
                                    }else {
                                        self.$utils._navigate_to_name('change-temporary-password')
                                    }
                                } else if(self.$route.query && self.$route.query.redirect) {
                                    self.$utils._navigate_to_path(self.$route.query.redirect)
                                } else {
                                    self.$utils._navigate_to_name('dashboard')
                                }
                            }
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        change_password_field_type: function() {
            const self = this;
            self.pwd_field_type = self.pwd_field_type === 'password' ? 'text' : 'password'
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    metaInfo: function(){
        return {
            'theme-color': '#91C4F2'
        }
    },
};
</script>